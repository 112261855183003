<template>
  <div class="row">
    <div class="col-12 col-sm-12">
      <div class="pb-1 pt-2">

        <div
          class="  "
          style="height: 50px; position: absolute; top: 88%"
          :style="
            floatingButtonDesign.buttonPos == 'Right'
              ? 'right:10%;'
              : 'left:10%;'
          "
        >
          <div>
            <button
              @click="triggerModal"
              class="btn px-3 py-2"
              :style="`background:${floatingButtonDesign.buttonColor};color:${floatingButtonDesign.buttonTextColor};border-radius:${floatingButtonDesign.borderRadius}px;font-size:${floatingButtonDesign.fontSize}px;`"
            >
              {{ floatingButtonDesign.buttonText }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <StaticPopUp
      :isDesktopView="isDesktopView"
      :pageDesign="pageDesign"
      :PopUpDesigns="floatingButtonDesign"
    />
  </div>
</template>

<script>
import StaticPopUp from "./StaticPopUp.vue";
export default {
  props: ["floatingButtonDesign", "isDesktopView", "pageDesign"],
  components: { StaticPopUp },
  methods: {
    triggerModal() {
      let modal = document.getElementById("quizPopupModal");
      modal.style.display = "block";
    },
  },
};
</script>

<style></style>
