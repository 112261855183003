<template>
  <div>
    <div id="quizPopupModal" class="quiz-modal">
      <div
        class="quiz-modal-content"
        :style="`width:${PopUpDesigns.popUpWidth}px;height:auto;position:relative`"
      >
        <p class="quiz-close" @click="CloseModal">&times;</p>
        <div
          class="row w-85 big-div m-auto "
          style="z-index: 100; min-height: 200px"
          :style="getPageDesign()"
        >
        <div
        class="bg lazyload"
        :data-bg="MainImgSrc(getImageSrc(pageDesign.imageBlock, isDesktopView))"
        v-if="pageDesign.pageStyles.imageAlign == 'no'"
        :key="getImageSrc(pageDesign.imageBlock, isDesktopView)"
        :style="
          getbackgroundImage(
            IsImageAdded,
            pageDesign.imageBlock,
            pageDesign.pageStyles.imageAlign,
            isDesktopView
          ) + getBgDivStyles() + `z-index:0 !important;`
        "
      ></div>

          <div
            :class="!IsImageAdded ? 'col-12' : imageAddedLayout"
            class=" "
            :style="
              getMainPaddingCss(pageDesign.pageStyles) +
              getScrollCSS(
                pageDesign.pageStyles,
                isDesktopView,
                pageDesign.imageBlock.style
              )
            "
          >
            <div>
              <div
                v-for="(pro, ind) in pageDesign.blocksArray"
                :key="'preview' + ind"
                class=""
              >
                <div
                  id="preview"
                  :style="getHeightWidth(pro.style)"
                  class="content"
                  style="position: relative"
                >
                  <div
                    v-if="pro.type == 'text'"
                    :style="
                      getColorCss(pro.style) +
                      getPaddingCss(pro.style) +
                      getMarginCss(pro.style,isDesktopView) +
                      getColorWithOpacity(pro.style) +
                      getTextRotate(pro.style) +
                      getLineHeight(pro.style) +
                      getTextAlign(pro.style) +
                      getFontStyle(pro.style,isDesktopView)
                    "
                    v-html="pro.content"
                  ></div>

                  <div
                    v-else
                    :style="
                      'display:flex;justify-content:' + pro.style.position + ';'
                    "
                  >
                    <button :style="getButtonCss(pro.style,isDesktopView)">
                      <span
                        :style="{
                          opacity: `${pro.style.textOpacity}%`,
                          fontSize: `${pro.style.fontSize}px`,
                        }"
                      >
                        {{ pro.text }}
                      </span>
                      {{ pro.style.alignItems }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
        class="col-6 col-xs-12 col-md-6 col-sm-12 px-0 col-12 col-xs-12 px-0 "
      
        :class="{
          'col-12 col-lg-12 col-xs-12': !isDesktopView,
          'image-division': isDesktopView,
        }"
      >
        <div :class="isDesktopView ? 'image-division' : ''">
          <div :style="height100(isDesktopView)">
            <LazyImage
              :style="
                getUniversalImageStyle(
                  isDesktopView,
                  pageDesign.imageBlock.style
                ) +
                getImageOpacity(pageDesign.imageBlock.style) +
                getImageRotation(pageDesign.imageBlock.style)  + `transform: rotate(0deg) scaleX(-1);`
              "
              :class="[
                `pic-SharePage`,
                pageDesign.imageBlock.src == '/images/default_start_page.png'
                  ? 'default-bg'
                  : '',
              ]"
              :src="MainImgSrc(pageDesign.imageBlock.src)"
              alt
            />
          </div>
        </div>
      </div>
         
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cssFunctionMixin from "../../customize/mixin/cssFunction.js";
export default {
  mixins: [cssFunctionMixin],
  components: {
  },
  props: ["PopUpDesigns", "isDesktopView", "pageDesign"],
  methods: {
    CloseModal() {
      let modal = document.getElementById("quizPopupModal");
      modal.style.display = "none";
    },
    getPageDesign() {
      let design =
        "text-align:" +
        this.pageDesign.pageStyles.textAlign +
        ";color:" +
        this.pageDesign.pageStyles.color +
        "; background:" +
        this.pageDesign.pageStyles.backgroundColor +
        "; text-align:" +
        this.pageDesign.pageStyles.textAlign +
        "; color:" +
        this.pageDesign.pageStyles.color +
        "; text-align:" +
        this.pageDesign.pageStyles.textAlign +
        "; height:" +
        this.PopUpDesigns.popUpHeight +
        "px;width:100%;";

      //  + (this.isDesktopView ? 'width:100%;'  : "width:450px;")

      return design;
    },
  },
  computed: {
 
    IsImageAdded() {
      if (this.pageDesign.imageBlock.src !== "") return true;
      return false;
    },
    imageAddedLayout() {
      if (this.pageDesign.pageStyles.imageAlign == "no") return "col-12";
      if (!this.isDesktopView) return "col-12";
      return "col-12 col-xs-12 col-md-6 col-sm-12  col-12 col-xs-12";
    },
  },
  mounted() {
    let modal = document.getElementById("quizPopupModal");
    window.onclick = function (event) {
      if (event.target == modal) {
        modal.style.display = "none";
      }
    };
  },
};
</script>

<style scoped>
.quiz-modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.quiz-modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 10px;
  border: 1px solid #888;
}

/* The Close Button */
.quiz-close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  z-index: 100;
}

.quiz-close:hover,
.quiz-close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
</style>
